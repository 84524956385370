.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.bg-login {
  background: linear-gradient(
    135deg,
    #cf6275,
    #b2b0be,
    #6291d0,
    #c37d92,
    #8788b1,
    #e2c6b7
  );
}

.bg-glassmorphism {
  border-radius: 16px;
  border: 1px solid #fff;
  background: linear-gradient(
    103deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
  box-shadow: 0px 0px 24px -1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
}
